<template>
  <div>
    <md-table v-model="list_data.mdData" md-sort="name" md-sort-order="asc" md-card md-fixed-header>
      <md-table-toolbar>
        <h1 class="md-title">Libros de ventas</h1>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" @click="go_to('/')">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
          <md-menu md-size="big" md-direction="bottom-end">
            <md-button class="md-icon-button" @click="go_to(`/form_ventas`)">
              <md-icon>add</md-icon>
            </md-button>
          </md-menu>
          <md-menu md-size="auto">
            <md-button @click.prevent="showDialog=true">
              <md-icon>filter_list</md-icon>
            </md-button>
          </md-menu>
        </md-menu>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Autorizacion"
        md-sort-by="authorization">{{item.authorization }}</md-table-cell>
        <md-table-cell md-label="Ejercicio fiscal" md-sort-by="year">
        {{ item.year }}</md-table-cell>
        <md-table-cell md-label="Fecha autorización"
        md-sort-by="date_authorization">{{ item.date_authorization|to_date }}</md-table-cell>
        <md-table-cell md-label="Activo"
        md-sort-by="status">{{ item.status|status_to_text }}</md-table-cell>
        <md-table-cell md-label="Seleccinar"  title="Click para elegir este libro">
          <md-button class="md-icon-button md-primary" @click="set_book(item)">
            <md-icon class="md-size-2x" >done</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Editar" >
          <md-button class="md-icon-button" @click="go_to(`/form_ventas/${item.id}`)">
            <md-icon class="md-size-1x" >edit</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-pagination
        :md-page-size="rowsPerPage"
        :md-page-options="[3, 5, 10, 15]"
        :md-update="list"
        :md-paginated-data.sync="list_data" />
    </md-table>
    <md-empty-state
      md-icon="free_breakfast" v-if="list_data.mdData.length === 0"
      md-label="Crear el libro de ventas"
      md-description="Aún no hay libros registrados, crea el primero.">
      <md-button
      md-theme="blue-button" class="md-accent
      md-raised">Crear libro de ventas</md-button>
    </md-empty-state>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
  </div>
</template>
<script>
import moment from 'moment';
/* eslint-disable */
export default {
  name: 'Ventas',
  created(){
    let current_filters = this.get_data_storage('filters_courses')
    if(current_filters){
      this.filters = current_filters
    }
    this.list(1, this.rowsPerPage)
  },
  data() {
    return {
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      rowsPerPage: 10,
      filters: {
        bimestre: '',
      },
      url: 'sale_book/',
      sending_request: false,
    };
  },
  methods: {
    set_book (instance) {
      if(!instance.status) {
        this.$swal.fire('Error!', 'No se puede elegir este libro, esta inactivo.', 'error')
        return false
      }
      this.remove_storage('book_purchase')
      this.save_storage('book_purchase', instance)
      let libro = this.get_data_storage('book_purchase')
      if (libro){
        this.$swal.fire('Seleccionado!', 'El libro ha sido Seleccionado.', 'success')  
        return false
      }
      this.$swal.fire('Error!', 'Paso algo y el libro no fue elegido.', 'error')

    },
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    reset_filters: function () {
      this.filters = {}
      this.remove_storage('filters_courses')
      this.list()
    },
    list(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
        this.sending_request = true
        payload.company = this.$store.state.company_obj.id
        payload.store = this.$store.state.store_obj.id
        this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }
        // self.list_data = data.data;
        this.sending_request = false
        // this.save_storage('filters_courses', this.filters,)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
